import React, { useState, useRef, useContext } from 'react';
import './login.css';
import dash from '../../assets/img/dash.png';
import { loginCall } from "../../apiCalls";
import { AuthContext } from "../../context/AuthContext";
import { Helmet } from 'react-helmet'; // Import Helmet

function LoginPage() {
  const clientId = useRef();
  const password = useRef();
  const { dispatch } = useContext(AuthContext);
  const [isRightPanelActive, setIsRightPanelActive] = useState(false);

  const handleSignInClick = (e) => {
    e.preventDefault();
    loginCall({ clientId: clientId.current.value, password: password.current.value }, dispatch);
  };

  return (
    <div className="login-page-body">
      <Helmet>
        <title>Zeroday Ops - Login</title>
        <meta name="description" content="Login to the Zeroday Ops Dashboard. Enter your credentials to access your account and manage cybersecurity solutions." />
        <meta name="keywords" content="Zeroday Ops, Login, Dashboard, Cybersecurity" />
      </Helmet>
      <h2 style={{ fontWeight: "bold" }}>Zeroday Ops Dashboard</h2>
      <div className={`login-page-container ${isRightPanelActive ? 'login-page-container-right-panel-active' : ''}`} id="login-page-container">
        <div className="login-page-form-container login-page-sign-in-container">
          <form action="login" onSubmit={handleSignInClick}>
            <input className="login-page-input" type="text" placeholder="Client ID" ref={clientId} />
            <input className="login-page-input" type="password" placeholder="Password" ref={password} />
            <button className="login-page-button">Sign In</button>
          </form>
        </div>
        <div className="login-page-overlay-container">
          <div className="login-page-overlay">
            <div className="login-page-overlay-panel login-page-overlay-right">
              <img src={dash} style={{ width: "150%" }} />
              <p className="login-page-p">Enter the provided credentials or request for credentials on admin@zerodayops.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
