import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Pages/home/Home';
import Login from './Pages/login/Login';
import WirelessSeccurity from './Pages/services/WirelessSeccurity';
import AppSec from './Pages/services/AppSec';
import NetworkSec from './Pages/services/NetworkSec';
import FirewallSec from './Pages/services/FirewallSec';
import Soc24x7 from './Pages/services/Soc24x7';
import PciDss from './Pages/services/Pci';
import CorporateTraining from './Pages/services/CorpEvent';
import Recon from './Pages/recon/Recon';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import About from './Pages/about-us/About';
import ContactPage from './Pages/contact/Contact';
import Dashboard from './Pages/dashboard/Dashboard';
import { AuthContext } from "./context/AuthContext";
import { useContext } from 'react';
import RedTeaming from './Pages/services/RedTeaming';
import CloudSecurity from './Pages/services/CloudSec';
import ReconResult from './Pages/recon/reconResult';

function App() {
  const { user } = useContext(AuthContext);
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={user ? <Dashboard /> : <Login />} />
          <Route path="/services/appsec" element={<AppSec />} />
          <Route path="/services/network-security" element={<NetworkSec />} />
          <Route path="/services/firewall-security" element={<FirewallSec />} />
          <Route path="/services/wireless-security" element={<WirelessSeccurity />} />
          <Route path="/services/red-teaming" element={<RedTeaming />} />
          <Route path="/services/soc24x7" element={<Soc24x7 />} />
          <Route path="/services/pci-dss" element={<PciDss />} />
          <Route path="/services/cloud-security" element={<CloudSecurity />} />
          <Route path="/service/corporate-training" element={<CorporateTraining />} />
          <Route path="/reconnaissance" element={<Recon />} />
          <Route path="/reconnaissance/:domain" element={<ReconResult />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Login />} />
        </Routes>
    </Router>
  );
}

export default App;
