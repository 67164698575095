import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import wireless1 from '../../assets/img/wireless1.png';
import wireless2 from '../../assets/img/wireless2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const WirelessNetworkAssessment = () => {
    return (
        <>
            <Helmet>
                <title>Wireless Network Assessment Services - Zeroday Ops</title>
                <meta name="description" content="Ensure the security and performance of your wireless networks with our comprehensive Wireless Network Assessment Services." />
                <meta name="keywords" content="Wireless Network Assessment, Network Security, Wireless Network Optimization, Cybersecurity Services" />
                {/* Organization Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZeroDay Ops",
          "url": "https://zerodayops.com/",
          "logo": "https://zerodayops.com/assets/img/logo192.png",
          "sameAs": [
            "https://www.linkedin.com/company/zeroday-ops/",
            "https://www.instagram.com/zerodayops/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9718047774",
            "contactType": "Customer Support",
            "areaServed": "US",
            "availableLanguage": "English"
          }
        }`}</script>

                {/* Breadcrumb Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://zerodayops.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Services",
              "item": "https://zerodayops.com/services"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Wireless Security",
              "item": "https://zerodayops.com/services/wireless-security"
            }
          ]
        }`}</script>

                {/* FAQ Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is Wireless Security?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Wireless Security protects wireless networks from unauthorized access and cyber threats."
              }
            },
            {
              "@type": "Question",
              "name": "Why is Wireless Security important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "It helps safeguard sensitive data transmitted over wireless networks."
              }
            }
          ]
        }`}</script>
            </Helmet>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        WIRELESS NETWORK ASSESSMENT SERVICES & NETWORK SECURITY
                    </h1>
                    <p className="appSec-subtitle">
                        The wireless network assessment culminates in a report of all the issues found and recommendations for resolving the problems and optimizing the performance of the network.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Wireless Network Assessment Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Expanding your network capabilities can be done well using wireless networks, but it can also be a source of harm to your information system. Deficiencies in its implementations or configurations can allow confidential information to be accessed in an unauthorized manner. This makes it imperative to closely monitor your wireless network while also conducting periodic Wireless Network assessments.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={wireless1} alt="Wireless Network Assessment Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <br />
                <br />
                <div className="appSec-methodology">
                    <h2 className="appSec-methodology-title">Wireless Network Assessment Methodology</h2>
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={wireless2} alt="Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Checklist:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Identifying Wireless Interference</li>
                                    <li>Areas of High-Density Users</li>
                                    <li>Low-Powered Devices or Applications</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Accessing the Wireless Network</li>
                                    <li>Addressing the Ever-Higher Demands of Users</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default WirelessNetworkAssessment;
