import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie'; // Importing the js-cookie library
import './reconresult.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';

const ReconResult = () => {
  const [progress, setProgress] = useState(0);
  const [lowRiskCount, setLowRiskCount] = useState(0);
  const [informationalCount, setInformationalCount] = useState(0);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { domain } = useParams();
  const [activeIndex, setActiveIndex] = useState(null);
  const [show, setShow] = useState(false);
  const [resultWait, setResultWait] = useState(1);
  // const { showResult, setShowResult } = useState(false);
  const [ipDetails, setIpDetails] = useState({});
  const [sslDetails, setSslDetails] = useState({});
  const [securityHeaders, setSecurityHeaders] = useState({});
  const [techStack, setTechStack] = useState({});
  const [subdomains, setSubdomains] = useState({});
  const [openPorts, setOpenPorts] = useState({});
  const [whoisOutput, setWhoisOutput] = useState({});
  const [digOutput, setDigOutput] = useState({});
  const [tracerouteData, setTracerouteData] = useState({});
  const [responseHeaders, setResponseHeaders] = useState('');
  const [securityHeadersCount, setSecurityHeadersCount] = useState(0);
  const [techCount, setTechCount] = useState(0);
  const [premium, setPremium] = useState('0');
  const [waitForm, setWaitForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleFormClose = () => setWaitForm(false);
  const name = useRef();
  const email = useRef();
  const company = useRef();
  const phone = useRef();

  const [showCalendlyModal, setShowCalendlyModal] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    const newContact = {
      name: name.current.value,
      email: email.current.value,
      company: company.current.value,
      phone: phone.current.value
    };

    try {
      await axios.post(`https://api.zerodayops.com/api/contact`, newContact);
      e.preventDefault();
      setShowCalendlyModal(true);
      // window.location.reload();
      Cookies.set('form_filled', 'true', { expires: 7 }); // Set cookie to prevent showing the form again
    } catch (err) {
      console.error(err);
    }
  };

  const submitHandler2 = async (e) => {
    e.preventDefault();
    const newContact = {
      name: name.current.value,
      email: email.current.value,
      phone: phone.current.value
    };

    try {
      await axios.post(`https://api.zerodayops.com/api/contact`, newContact);
      e.preventDefault();
      setWaitForm(false);
      // setShowCalendlyModal(true);
      // window.location.reload();
      Cookies.set('form_filled', 'true', { expires: 7 }); // Set cookie to prevent showing the form again
    } catch (err) {
      console.error(err);
    }
  };

  const handleBookAppointment = (e) => {
    e.preventDefault();
    setShowCalendlyModal(true); // Show modal on button click
  };

  const closeModal = () => {
    setShowCalendlyModal(false); // Close the modal
  };
  const [dropdowns, setDropdowns] = useState({
    ipDetails: false,
    sslDetails: false,
    securityHeaders: false,
    techStack: false,
    subdomains: false,
    openPorts: false,
    tracert: false,
    whoisOutput: false,
    digOutput: false,
    responseHeaders: false
  });
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        setWaitForm(true);
        // let lowCount = 0;
        // let infoCount = 0;
        let techCount = 0;
        let headersCount = 0;
        setProgress(0); // Reset progress at the start

        // First fetch attempt
        let response = await axios.get(`https://api.zerodayops.com/api/analysis/${domain}`);

        // If response data is null, wait for 60 seconds
        if (response.data == null) {
          // setResultWait(0);

          for (let i = 0; i < 60; i++) {
            setProgress((prev) => Math.min(prev + 0.83, 100)); // Increase progress
            await sleep(1000); // Wait for 1 second
            // console.log(i);
          } window.location.reload();
        }

        // If data is successfully fetched, update state
        if (response.data) {
          const data = response.data; // Save to a local variable for easier access

          setData(data);

          if (data.ipDetails) {
            setIpDetails(JSON.parse(data.ipDetails));
          }

          if (data.sslDetails) {
            setSslDetails(JSON.parse(data.sslDetails));
          }

          if (data.securityHeaders) {
            const parsedSecurityHeaders = JSON.parse(data.securityHeaders);
            setSecurityHeaders(parsedSecurityHeaders);

            // Accessing security_headers safely
            if (parsedSecurityHeaders.security_headers) {
              Object.entries(parsedSecurityHeaders.security_headers).forEach(([key, value]) => {
                if (value === 'Missing') headersCount++;
                // else infoCount++;
              });
            }
          }

          if (data.techStack) {
            const parsedTechStack = JSON.parse(data.techStack);
            setTechStack(parsedTechStack);

            techStack[`https://${domain}`]?.tech?.forEach((item) => {
              if (item.version) techCount++;
            });
          }

          if (data.subdomains) {
            setSubdomains(JSON.parse(data.subdomains));
          }

          if (data.openPorts) {
            setOpenPorts(JSON.parse(data.openPorts));
          }

          if (data.whoisOutput) {
            setWhoisOutput(JSON.parse(data.whoisOutput));
          }

          if (data.digOutput) {
            setDigOutput(JSON.parse(data.digOutput));
          }

          if (data.traceroute) {
            const tracerouteJsonString = data.traceroute.substring(data.traceroute.indexOf('{'));
            setTracerouteData(JSON.parse(tracerouteJsonString));
          }

          if (data.responseHeaders) {
            setResponseHeaders(data.responseHeaders.split('\r\n').filter(Boolean));
          }

          setLowRiskCount(headersCount);
          setSecurityHeadersCount(headersCount);
          setInformationalCount(techCount);
          setTechCount(techCount);
          setPremium('👑');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Failed to load data. Please try again.');
      } finally {
        setProgress(100); // Set progress to 100% after data is fetched
        setIsLoading(false); // Update loading state
      }
    };

    fetchData();
  }, [domain]);

  // Function to toggle dropdown visibility
  const toggleDropdown = (section) => {
    setDropdowns({ ...dropdowns, [section]: !dropdowns[section] });
  };

  // Check if the form has already been filled
  useEffect(() => {
    const formFilled = Cookies.get('form_filled');
    if (formFilled) {
      setShow(false); // Don't show the form if cookie is set
      setWaitForm(false); // Also ensure wait form is closed if it was open
    }
  }, []);

  return (
    <>
      {show && (
        <div className="modal-overlay" style={{ marginTop: "90px", height: "90%" }}>
          <div className="modal-container">
            <div className="modal-header">
              <h2 style={{ color: "black", fontWeight: "bold" }}>Schedule a Free 1:1 Consultation Call</h2>
              <button className="close-btn" onClick={handleClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ color: "black" }}>In your 30-min call with cyber security expert:</p>
              {/* <br /> */}
              <ul style={{ color: "grey" }}>
                <li><i>- Discuss about your <strong>pentest requirements</strong> and security goals.</i></li>
                <li><i>- Get a <strong>security certificate</strong> after getting secured.</i></li>
                <li><i>- Save over <strong>million dollars</strong> worth of digital assets.</i></li>
              </ul>

              <form className="modal-form" onSubmit={submitHandler} style={{ color: "black" }}>
                <div className="form-group">
                  <label>Name</label>
                  <input ref={name} type="text" placeholder="Name" required />
                </div>
                <div className="form-group">
                  <label>Work Email</label>
                  <input ref={email} type="email" placeholder="Work Email" required />
                </div>
                <div className="form-group">
                  <label>Company Name</label>
                  <input ref={company} type="text" placeholder="Company Name" required />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input ref={phone} type="text" placeholder="Phone Number" required />
                </div>

                <div className="modal-footer">
                  <button className="submit-btn" type="submit">
                    Choose a Meeting Slot
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="modal-footer">
              <button className="submit-btn" onClick={submitHandler}>Choose a Meeting Slot</button>
            </div> */}
          </div>
        </div>
      )}
      {waitForm && !show && (
        <div className="modal-overlay" style={{ marginTop: "90px", height: "90%" }}>
          <div className="modal-container">
            <div className="modal-header">
              <h2 style={{ color: "black", fontWeight: "bold" }}>While your results are being generated, please input the below details so we can keep you up to date with all the latest cyber security threats</h2>
              <button className="close-btn" onClick={handleFormClose}>
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p style={{ color: "black" }}>In your 30-min call with cyber security expert:</p>
              {/* <br /> */}
              <ul style={{ color: "grey" }}>
                <li><i>- Discuss about your <strong>pentest requirements</strong> and security goals.</i></li>
                <li><i>- Get a <strong>security certificate</strong> after getting secured.</i></li>
                <li><i>- Save over <strong>million dollars</strong> worth of digital assets.</i></li>
              </ul>

              <form className="modal-form" onSubmit={submitHandler2} style={{ color: "black" }}>
                <div className="form-group">
                  <label>Name</label>
                  <input ref={name} type="text" placeholder="Name" required />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input ref={email} type="email" placeholder="Work Email" required />
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <input ref={phone} type="text" placeholder="Phone Number" required />
                </div>

                <div className="modal-footer">
                  <button className="submit-btn" type="submit">
                    See results
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="modal-footer">
              <button className="submit-btn" onClick={submitHandler}>Choose a Meeting Slot</button>
            </div> */}
          </div>
        </div>
      )}
      <NavBar />
      <div className='recon-result-full'>
        <div className="host-light-scan">
          <div className="scan-header">
            <div className="side-by-side-recon">
              <h2>
                Host Light Scan report for <span>https://{domain}</span>
              </h2>
              <a href="#" className="cta-button" onClick={handleShow}>
                Upgrade For Deep Scan
              </a>
            </div>
            <p>Completed on {new Date().toLocaleString()}</p>
          </div>

          <div className="scan-progress">
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progress}%` }}>
                <span>{Math.round(progress)}%</span>
              </div>
            </div>
            <p className="progress-status">Completed</p>
          </div>

          <div className="scan-results">
            <div className="result-card critical" onClick={handleShow}>
              <strong style={{ fontSize: "25px" }}>{premium}</strong>
              <p style={{ fontWeight: "bold" }}>Critical</p>
            </div>
            <div className="result-card high-risk" onClick={handleShow}>
              <strong style={{ fontSize: "25px" }}>{premium}</strong>
              <p style={{ fontWeight: "bold" }}>High</p>
            </div>
            <div className="result-card medium-risk" onClick={handleShow}>
              <strong style={{ fontSize: "25px" }}>{premium}</strong>
              <p style={{ fontWeight: "bold" }}>Medium</p>
            </div>
            <div className="result-card low-risk" onClick={handleShow}>
              <h4>{lowRiskCount}</h4>
              <p style={{ fontWeight: "bold" }}>Low</p>
            </div>
            <div className="result-card informational" onClick={handleShow}>
              <h4>{informationalCount}</h4>
              <p style={{ fontWeight: "bold" }}>Informational</p>
            </div>
          </div>
        </div>

        <div className="recon-result">
          <div className="container-recon">
            {/* IP Details */}
            <section className="section" onClick={() => toggleDropdown('ipDetails')}>
              <h3>IP Details</h3>
              {dropdowns.ipDetails && (
                <ul>
                  <li>IP: {ipDetails.ip}</li>
                  <li>Network: {ipDetails.network}</li>
                  <li>Version: {ipDetails.version}</li>
                  <li>City: {ipDetails.city}</li>
                  <li>Region: {ipDetails.region}</li>
                  <li>Region Code: {ipDetails.region_code}</li>
                  <li>Country: {ipDetails.country_name} ({ipDetails.country_code})</li>
                  <li>Continent Code: {ipDetails.continent_code}</li>
                  <li>Latitude: {ipDetails.latitude}</li>
                  <li>Longitude: {ipDetails.longitude}</li>
                  <li>Timezone: {ipDetails.timezone}</li>
                  <li>UTC Offset: {ipDetails.utc_offset}</li>
                  <li>Currency: {ipDetails.currency} ({ipDetails.currency_name})</li>
                  <li>Languages: {ipDetails.languages}</li>
                  <li>ASN: {ipDetails.asn}</li>
                  <li>Organization: {ipDetails.org}</li>
                </ul>
              )}
            </section>

            {/* SSL Enumeration */}
            <section className="section" onClick={() => toggleDropdown('sslDetails')}>
              <h3>SSL Enumeration</h3>
              {dropdowns.sslDetails && (
                <ul>
                  <li>Domain: {sslDetails.domain}</li>
                  <li>Issuer: {sslDetails.issuer}</li>
                  <li>Valid From: {sslDetails.valid_from}</li>
                  <li>Valid To: {sslDetails.valid_to}</li>
                  {/* <li>Serial Number: {sslDetails.serial_number}</li> */}
                  {/* <li>SHA256 Fingerprint: {sslDetails.fingerprint_sha256}</li> */}
                  {/* <li>SHA512 Fingerprint: {sslDetails.fingerprint_sha512}</li> */}
                </ul>
              )}
            </section>

            {/* Security Headers */}
            <section className="section" onClick={() => toggleDropdown('securityHeaders')}>
              <h3>Security Headers</h3>{securityHeadersCount ? (<p style={{ color: "red" }}>⚠️ {securityHeadersCount} Low Vulnerabilities Found</p>) : ""}
              {dropdowns.securityHeaders && (
                <ul>
                  {Object.entries(securityHeaders.security_headers).map(([key, value]) => (
                    <li key={key}>
                      {key}: <strong style={{ color: value === 'Missing' ? 'red' : 'green' }}>{value}</strong>
                    </li>
                  ))}
                  <br />
                  <p><strong>Risk: </strong> Absence of key security headers (like CSP, X-Frame-Options, or HSTS) can leave web applications vulnerable to attacks such as cross-site scripting (XSS), clickjacking, and man-in-the-middle attacks. Implementing proper headers enhances the protection of both the application and its users.</p>
                </ul>
              )}
            </section>

            {/* Tech Stack */}
            <section className="section" onClick={() => toggleDropdown('techStack')}>
              <h3>Tech Stack</h3>{informationalCount ? (<p style={{ color: "red" }}>⚠️ {techCount} Informational Vulnerabilities Found</p>) : ""}
              {dropdowns.techStack && (
                <ul>
                  {techStack[`https://${domain}`]?.tech?.map((item, index) => (
                    <li key={index}>
                      {item.name} {item.version ? <strong style={{ color: "red" }}> (v{item.version})</strong> : ''}
                    </li>
                  ))}
                  <br />
                  <p><strong>Risk: </strong> Exposed service banners can provide detailed information about software versions, which attackers can leverage to target known vulnerabilities. Disabling or obfuscating banners reduces the risk of information leakage.</p>
                </ul>
              )}
            </section>

            {/* Subdomains */}
            <section className="section" onClick={() => toggleDropdown('subdomains')}>
              <h3>Subdomains</h3>
              {dropdowns.subdomains && (
                <ul>
                  {subdomains.subdomains.map((subdomain, index) => (
                    <li key={index}>{subdomain}</li>
                  ))}
                </ul>
              )}
            </section>

            {/* Open Ports */}
            <section className="section" onClick={() => toggleDropdown('openPorts')}>
              <h3>Open Ports</h3>
              {dropdowns.openPorts && (
                <ul>
                  {openPorts.openPorts.map((port, index) => (
                    <li key={index}>Port: {port} (Open)</li>
                  ))}
                  <br />
                  <p><strong>Risk: </strong> Open ports can be exploited by attackers to gain unauthorized access to services running on the system, increasing the risk of vulnerability exploitation. Proper port management and firewall configurations are essential to mitigate these threats.</p>
                </ul>
              )}
            </section>

            <section className="section" onClick={() => toggleDropdown('responseHeaders')}>
              <h3>Response Headers</h3>
              {dropdowns.responseHeaders && (
                <ul>
                  {responseHeaders.map((header, index) => (
                    <li key={index}>{header}</li>
                  ))}
                </ul>
              )}
            </section>

            <section className="section" onClick={() => toggleDropdown('traceroute')}>
              <h3>Traceroute</h3>
              {dropdowns.traceroute && (
                <ul>
                  {tracerouteData.hops.map((hop, index) => (
                    <li key={index}>Hop {hop.hop}: {hop.ip}</li>
                  ))}
                  <br />
                  <p><strong>Risk: </strong> Traceroute can reveal the internal network infrastructure, including hops and IP addresses, which attackers can use to map the network for further attacks. Limiting ICMP responses can reduce this exposure.</p>
                </ul>
              )}
            </section>

            {/* WHOIS */}
            <section className="section" onClick={() => toggleDropdown('whoisOutput')}>
              <h3>WHOIS</h3>
              {dropdowns.whoisOutput && (
                <ul>
                  <li>Domain Name: {whoisOutput.domainName}</li>
                  <li>Registrar: {whoisOutput.registrar}</li>
                  <li>Creation Date: {whoisOutput.creationDate}</li>
                </ul>
              )}
            </section>

            {/* DIG */}
            <section className="section" onClick={() => toggleDropdown('digOutput')}>
              <h3>DIG</h3>
              {dropdowns.digOutput && (
                <ul>
                  {digOutput.ips.map((ip, index) => (
                    <li key={index}>{ip}</li>
                  ))}
                </ul>
              )}
            </section>
          </div>
        </div>
      </div>
      <Footer />
      {/* Modal for Calendly iframe */}
      {showCalendlyModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <iframe
              src="https://calendly.com/zerodayops/schedule-a-call?back=1&month=2024-10"
              style={{ width: '100%', height: '600px', border: 'none' }}
              frameBorder="0"
              scrolling="no"
              title="Book An Appointment"
              allowTransparency="true">
            </iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default ReconResult;
