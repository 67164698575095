import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/ZeroDayOpsLogo.svg";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";
import navIcon4 from "../assets/img/nav-icon4.svg";
import navIcon5 from "../assets/img/nav-icon5.svg";
import startupIndiaLogo from "../assets/img/startup-india-hub-logo.png"

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <br/>
        <br/>
        <Row className="align-items-center vertical-center">
          <MailchimpForm />
          <Col size={25} sm={6}>
            <img className="logo logo-dimension" src={logo} alt="ZeroDay Ops Logo" />
            <img className="logo-dimension" src={startupIndiaLogo} alt="Starup India Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/zeroday-ops/" aria-label="ZeroDay Ops LinkedIn">
                <img src={navIcon1} alt="LinkedIn Icon" />
              </a>
              {/* <a href="#" aria-label="ZeroDay Ops Facebook">
              <img src={navIcon2} alt="Facebook Icon"/>
              </a> */}
              <a href="https://www.instagram.com/zerodayops" aria-label="ZeroDay Ops Instagram">
                <img src={navIcon3} alt="Instagram Icon" />
              </a>
              <a href="https://Wa.me/+918750539192" aria-label="ZeroDay Ops Whatsapp">
              <img src={navIcon4} alt="Whatsapp Icon"/>
              </a>
              <a href="https://X.com/zerodayops" aria-label="ZeroDay Ops Twitter">
              <img src={navIcon5} alt="Twitter Icon"/>
              </a>
            </div>
            <br/>
            <br/>
            <p>Copyright 2024. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
