import React from 'react';
import wolf from "../assets/tools/wolf.webp";
import recon from "../assets/tools/recon.png";
import va from "../assets/tools/va.png";
import network from "../assets/tools/network.png"
import tech from "../assets/tools/tech.png";
import traceroute from "../assets/tools/traceroute.png";
import directories from "../assets/tools/directories.png";
import ssl from "../assets/tools/ssl.png";
import ports from "../assets/tools/ports.png";
import secheaders from "../assets/tools/secheaders.png";

const ScannerFeatures = () => {
  return (
    <div className="features-container">
      <div className="feature-item">
        <img src={recon} alt="wolf logo" className="feature-icon" />
        <div className="feature-content">
          <h3>Comprehensive Reconnaissance</h3>
          <p>
            Our reconnaissance tool provides a wide range of features to gather critical information. From <strong>IP Information</strong> to <strong>Sub-Domain Enumeration</strong>, it offers a thorough overview of your target's online presence, enabling effective security assessments.
          </p>
        </div>
      </div>
      
      <div className="feature-item">
        <div className="feature-content">
          <h3>Network Analysis</h3>
          <p>
            Discover <strong>Open Ports</strong> and enumerate <strong>IP Addresses</strong> with precision. Our tool ensures you have a complete understanding of the network's structure and potential entry points for vulnerabilities.
          </p>
        </div>
        <img src={network} alt="wolf logo" className="feature-icon" />
      </div>
      
      <div className="feature-item">
        <img src={va} alt="wolf logo" className="feature-icon" />
        <div className="feature-content">
          <h3>Vulnerability Assessment</h3>
          <p>
            Identify <strong>Basic Network Vulnerabilities</strong> and assess <strong>Live Sub-Domains</strong> with our state-of-the-art scanning techniques. The tool performs checks on <strong>Security Headers</strong> and <strong>SSL Certificates</strong> to ensure your defenses are robust against attacks.
          </p>
        </div>
      </div>

      <div className="feature-item">
        <div className="feature-content">
          <h3>Technology Detection</h3>
          <p>
            Get insights into the <strong>Technologies Used</strong> on a site and enumerate all accessible <strong>URLs</strong>. This information is vital for understanding the attack surface and planning your penetration testing strategies.
          </p>
        </div>
        <img src={tech} alt="wolf logo" className="feature-icon" />
      </div>

      <div className="feature-item">
        <img src={directories} alt="wolf logo" className="feature-icon" />
        <div className="feature-content">
          <h3>Sensitive Directory Enumeration</h3>
          <p>
            Our tool automatically checks for <strong>Sensitive Directories</strong> that could expose your organization to risks. Coupled with <strong>Traceroute</strong> capabilities, it helps map the network topology effectively.
          </p>
        </div>
      </div>

      <div className="feature-item">
        <div className="feature-content">
          <h3>Open Ports Discovery</h3>
          <p>
            The scanner identifies <strong>Open Ports</strong> on your target, helping you understand which services are exposed and could potentially be exploited by attackers.
          </p>
        </div>
        <img src={ports} alt="wolf logo" className="feature-icon" />
      </div>

      <div className="feature-item">
        <img src={secheaders} alt="wolf logo" className="feature-icon" />
        <div className="feature-content">
          <h3>Security Headers Checks</h3>
          <p>
            Evaluate the effectiveness of your web application’s security by checking <strong>Security Headers</strong>. Our tool identifies missing or misconfigured headers that could leave your application vulnerable.
          </p>
        </div>
      </div>

      <div className="feature-item">
        <div className="feature-content">
          <h3>SSL Certificate Verification</h3>
          <p>
            Ensure the security of your site with <strong>SSL Checks</strong>. Our tool assesses your SSL certificate to confirm it is valid, correctly configured, and up to date, protecting user data from interception.
          </p>
        </div>
        <img src={ssl} alt="wolf logo" className="feature-icon" />
      </div>

      <div className="feature-item">
        <img src={traceroute} alt="wolf logo" className="feature-icon" />
        <div className="feature-content">
          <h3>Traceroute Analysis</h3>
          <p>
            Use the <strong>Traceroute</strong> feature to understand the path data takes to reach your target. This helps in identifying potential network issues and the geographical location of servers.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScannerFeatures;
