import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import pci1 from '../../assets/img/pci1.png';
import pci2 from '../../assets/img/pci2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const PciAssessment = () => {
    return (
        <>
            <Helmet>
                <title>Zeroday Ops - PCI DSS Compliance Services</title>
                <meta name="description" content="Achieve PCI DSS compliance with our comprehensive services to protect cardholder data and maintain a secure payment environment." />
                <meta name="keywords" content="PCI DSS Compliance, Cardholder Data Protection, Security Assessment, Cybersecurity, Payment Security" />
                {/* Organization Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZeroDay Ops",
          "url": "https://zerodayops.com/",
          "logo": "https://zerodayops.com/assets/img/logo192.png",
          "sameAs": [
            "https://www.linkedin.com/company/zeroday-ops/",
            "https://www.instagram.com/zerodayops/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9718047774",
            "contactType": "Customer Support",
            "areaServed": "US",
            "availableLanguage": "English"
          }
        }`}</script>

                {/* Breadcrumb Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://zerodayops.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Services",
              "item": "https://zerodayops.com/services"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "PCI DSS Compliance",
              "item": "https://zerodayops.com/services/pci-dss"
            }
          ]
        }`}</script>

                {/* FAQ Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is PCI DSS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "PCI DSS stands for Payment Card Industry Data Security Standard, which is a set of security standards designed to ensure that all companies that accept, process, store, or transmit credit card information maintain a secure environment."
              }
            },
            {
              "@type": "Question",
              "name": "Why is PCI DSS Compliance important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "It protects sensitive cardholder data and helps prevent data breaches."
              }
            }
          ]
        }`}</script>
            </Helmet>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        PCI DSS COMPLIANCE SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our PCI DSS compliance services ensure that your organization meets all requirements to protect cardholder data and maintain a secure payment environment.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">PCI DSS Compliance Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Achieving PCI DSS compliance is crucial for organizations that handle payment card data. Compliance with the Payment Card Industry Data Security Standard (PCI DSS) protects cardholder data, reduces the risk of breaches, and ensures your organization meets industry regulations. Our services provide a comprehensive assessment and remediation plan to achieve and maintain compliance.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={pci1} style={{ width: '300px' }} alt="PCI DSS Compliance Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={pci2} alt="PCI DSS Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Assessing Cardholder Data Environment (CDE)</li>
                                    <li>Evaluating Data Encryption and Protection</li>
                                    <li>Testing Network Security Controls</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Monitoring and Logging Mechanisms</li>
                                    <li>Assessing Access Control Mechanisms</li>
                                    <li>Providing Remediation Plans</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <h3 className="appSec-checklist-title">PCI DSS Requirements:</h3>
                <div className="appSec-checklist-items">
                    <ul className="appSec-checklist-column">
                        <li>Install and maintain a firewall configuration to protect cardholder data</li>
                        <li>Do not use vendor-supplied defaults for system passwords and other security parameters</li>
                        <li>Protect stored cardholder data</li>
                        <li>Encrypt transmission of cardholder data across open, public networks</li>
                        <li>Protect all systems against malware and regularly update anti-virus software or programs</li>
                        <li>Develop and maintain secure systems and applications</li>
                    </ul>
                    <ul className="appSec-checklist-column">
                        <li>Restrict access to cardholder data by business need to know</li>
                        <li>Identify and authenticate access to system components</li>
                        <li>Restrict physical access to cardholder data</li>
                        <li>Track and monitor all access to network resources and cardholder data</li>
                        <li>Regularly test security systems and processes</li>
                        <li>Maintain a policy that addresses information security for all personnel</li>
                    </ul>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default PciAssessment;
