import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import cloud1 from '../../assets/img/cloud1.png';
import cloud2 from '../../assets/img/cloud2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const CloudSecurity = () => {
    return (
        <>
            <Helmet>
                <title>Zeroday Ops - Cloud Security Services</title>
                <meta name="description" content="Ensure the security of your organization's cloud environments with our comprehensive Cloud Security Services, protecting your data and applications from cyber threats." />
                <meta name="keywords" content="Cloud Security, Cybersecurity, Data Protection, Compliance, Cloud Configuration, IAM" />
                {/* Organization Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZeroDay Ops",
          "url": "https://zerodayops.com/",
          "logo": "https://zerodayops.com/assets/img/logo192.png",
          "sameAs": [
            "https://www.linkedin.com/company/zeroday-ops/",
            "https://www.instagram.com/zerodayops/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9718047774",
            "contactType": "Customer Support",
            "areaServed": "US",
            "availableLanguage": "English"
          }
        }`}</script>

                {/* Breadcrumb Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://zerodayops.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Services",
              "item": "https://zerodayops.com/services"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Cloud Security",
              "item": "https://zerodayops.com/services/cloud-security"
            }
          ]
        }`}</script>

                {/* FAQ Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is Cloud Security?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud Security refers to the strategies, policies, and technologies used to protect cloud-based data and applications."
              }
            },
            {
              "@type": "Question",
              "name": "Why is Cloud Security important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Cloud Security ensures that sensitive data stored in the cloud is protected from cyberattacks, breaches, and unauthorized access."
              }
            }
          ]
        }`}</script>
            </Helmet>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        CLOUD SECURITY SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our Cloud Security Services ensure that your organization's cloud environments are secure, compliant, and resilient against modern cyber threats.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Cloud Security Services: Overview</h2>
                        <p className="appSec-overview-text">
                            As organizations increasingly move their operations to the cloud, ensuring the security of cloud environments has become critical. Our Cloud Security Services help protect your data, applications, and infrastructure from unauthorized access and cyber threats, while also ensuring compliance with industry standards and regulations.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={cloud1} style={{ width: '250px' }} alt="Cloud Security Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={cloud2} alt="Cloud Security Methodology" style={{ width: "50%", height: "50%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Assessing Cloud Configuration and Security Settings</li>
                                    <li>Evaluating Identity and Access Management (IAM)</li>
                                    <li>Analyzing Data Encryption and Protection Mechanisms</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing for Compliance with Industry Standards</li>
                                    <li>Monitoring and Logging Cloud Activity</li>
                                    <li>Providing Incident Response and Remediation Strategies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default CloudSecurity;
