import React from "react";
import oscp from "../assets/certs/oscp.png";
import ecppt from "../assets/certs/ecppt.png";
import ewptx from "../assets/certs/ewptx.png";
import ceh from "../assets/certs/CEH.png";
import cap from "../assets/certs/cap.png";
import crtp from "../assets/certs/crtp.png";
import crto from "../assets/certs/crto.png";
import az900 from "../assets/certs/az900.png";

const Certifications = () => {
  const certifications = [
    {
      title: "Offensive Security Certified Professional (OSCP)",
      image: oscp,
    },
    {
        title: "Certified Professional Penetration Tester (eCPPT)",
        image: ecppt,
    },
    {
        title: "Web application Penetration Tester eXtreme (eWPTX)",
        image: ewptx,
    },
    {
      title: "Certified Ethical Hacker (CEH)",
      image: ceh,
    },
    {
      title: "Certified AppSec Pentester (CApen)",
      image: cap,
    },
    {
      title: "Certified Red Team Professional",
      image: crtp,
    },
    {
      title: "Microsoft Azure Fundamentals Certification (AZ-900)",
      image: az900,
    },
    {
      title: "Certified Red Team Operator (CRTO)",
      image: crto,
    }
  ];

  return (
    <section className="certifications-container">
      <h2 className="certifications-heading">Our Strong Industry Experience</h2>
      <p className="certifications-subheading">
        Trust our elite team of cyber security experts to enhance your security
      </p>
      <div className="certifications-grid">
        {certifications.map((cert, index) => (
          <div className="certification-card" key={index}>
            <img src={cert.image} alt={cert.title} className="certification-image" />
            <p className="certification-title">{cert.title}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Certifications;
