import React from "react";
// import webAppImage from "../assets/img/WebApp.png"; // Example for web application box
// import mobileAppImage from "../assets/img/MobileApp.png"; // Example for mobile application box
// import cloudImage from "../assets/img/Cloud.png";
// import networkImage from "../assets/img/Network.png";
// import apiImage from "../assets/img/API.png";
// import sourceCodeImage from "../assets/img/SourceCode.png";
import web from "../assets/img/web.webp";
import mobile from "../assets/img/mobile.webp";
import cloud from "../assets/img/cloud.webp";
import network from "../assets/img/network.webp";
import api from "../assets/img/api.webp";
import code from "../assets/img/1.webp";
import { useNavigate } from "react-router-dom";


const VAPTServices = () => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = `/contact`; 
    navigate(path);
  }
  const services = [
    {
      title: "Web Application",
      description:
        "Identify and exploit vulnerabilities in your web applications to ensure they are secure against cyber threats.",
      image: web,
    },
    {
      title: "Mobile Application",
      description:
        "Identify and exploit vulnerabilities in your mobile applications to ensure they are secure against cyber threats.",
      image: mobile,
    },
    {
      title: "Cloud",
      description:
        "Assess and enhance the security of your cloud environments and to protect against potential threats.",
      image: cloud,
    },
    {
      title: "Network",
      description:
        "Assess and enhance the security of your network infrastructure to protect against potential threats.",
      image: network,
    },
    {
      title: "API",
      description:
        "Detect and exploit vulnerabilities in your APIs to prevent data breaches and unauthorized access.",
      image: api,
    },
    {
      title: "Source Code Review",
      description:
        "Review code to identify and fix security vulnerabilities, bugs, and adherence to coding standards.",
      image: code,
    },
  ];

  return (
    <section className="vapt-services-container">
      <h2 className="vapt-services-heading">
        Leading VAPT Services and Certified Penetration Testing by Industry Leaders
      </h2>
      <p className="vapt-services-subheading">
      Zeroday Ops offer a comprehensive range of cybersecurity services designed to safeguard your digital assets. Our expertise includes Vulnerability Assessment and Penetration Testing (VAPT), penetration testing across web, network, Android, iOS, thick client, and thin client environments, as well as firewall security testing.
      </p>
      <div className="vapt-services-grid">
        {services.map((service, index) => (
          <div className="vapt-service-card" key={index}>
            <img src={service.image} alt={service.title} className="vapt-service-image" />
            <h3 className="vapt-service-title">{service.title}</h3>
            <p className="vapt-service-description">{service.description}</p>
          </div>
        ))}
      </div>
      <button className="vapt-start-button" onClick={routeChange}>
        Start Your VAPT Testing
        <span className="vapt-arrow">&#8594;</span>
      </button>
    </section>
  );
};

export default VAPTServices;
