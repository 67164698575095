import React from 'react'; 
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import ct from '../../assets/img/ct.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Contact } from '../../components/Contact';
import { Link } from "react-router-dom";

const CorporateCyberTraining = () => {
    return (
        <>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        CORPORATE CYBERSECURITY TRAINING SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Equip your employees with essential cybersecurity skills to safeguard your organization from modern threats. Our training programs are designed to empower your workforce to identify, prevent, and respond to cyber risks effectively.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Corporate Cybersecurity Training: Overview</h2>
                        <p className="appSec-overview-text">
                            In today’s digital world, human error remains one of the top causes of data breaches. By implementing a comprehensive cybersecurity training program, you can significantly reduce your organization’s exposure to cyber threats. Our tailored corporate cybersecurity training ensures your employees understand the critical role they play in securing your organization.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={ct} style={{ width: '250px' }} alt="Corporate Cybersecurity Training" />
                    </div>
                </div>
                <button className='appsecButton'><Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>Enroll Your Team <ArrowRightCircle size={25} /></Link></button>
                <br />
                <br />
                <div className="appSec-overview">
                    <h2 className="appSec-overview-title">Key Cybersecurity Training Topics</h2>
                    <br />
                    <br />
                    <h4>Security Awareness Training</h4>
                    <p className="appSec-overview-text">
                        Our Security Awareness Training provides employees with the knowledge to recognize and avoid common cyber threats, including phishing, malware, and social engineering attacks. It is vital that all staff understand the importance of cybersecurity hygiene in their daily tasks.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Core Components:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Recognizing Phishing Emails</li>
                                    <li>Understanding Password Security</li>
                                    <li>Identifying Suspicious Activities</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Maintaining Clean Device Practices</li>
                                    <li>Data Protection Best Practices</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Phishing Simulation Training</h4>
                    <p className="appSec-overview-text">
                        Phishing is one of the most common methods attackers use to gain unauthorized access. Our phishing simulation training helps employees learn to recognize and avoid these types of attacks by testing them in real-world scenarios.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Training Highlights:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Simulated Phishing Emails</li>
                                    <li>Immediate Feedback on Actions</li>
                                    <li>Tracking and Reporting Employee Progress</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Understanding Phishing Indicators</li>
                                    <li>Continuous Learning and Improvement</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Access Control and Identity Management</h4>
                    <p className="appSec-overview-text">
                        Ensuring that the right people have access to the right resources is critical. This training focuses on strengthening access control policies, secure authentication methods, and best practices for managing user identities across the organization.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Key Takeaways:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Implementing Role-Based Access Control (RBAC)</li>
                                    <li>Strengthening Multi-Factor Authentication (MFA)</li>
                                    <li>Monitoring Privileged Access Accounts</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Mitigating Insider Threats</li>
                                    <li>Regular Access Audits and Reviews</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Data Protection and Privacy</h4>
                    <p className="appSec-overview-text">
                        Employees must understand the importance of protecting sensitive data and maintaining privacy. This training focuses on the principles of data protection, privacy regulations, and practical steps to secure data in both physical and digital forms.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Core Topics:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Understanding Data Classification</li>
                                    <li>Implementing Encryption Best Practices</li>
                                    <li>Compliance with GDPR, HIPAA, and Other Standards</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Managing Third-Party Risks</li>
                                    <li>Data Loss Prevention (DLP) Tools and Techniques</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <h4>Incident Response Training</h4>
                    <p className="appSec-overview-text">
                        Preparing for the inevitable. Incident Response Training equips your team with the knowledge to react quickly and effectively in the event of a cyberattack. Learn how to detect, contain, and recover from incidents while minimizing damage.
                    </p>
                    <br />
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Training Modules:</h3>
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Incident Detection and Containment</li>
                                    <li>Root Cause Analysis and Forensics</li>
                                    <li>Communication and Escalation Protocols</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Post-Incident Recovery and Lessons Learned</li>
                                    <li>Developing and Testing Incident Response Plans</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <br />
                </div>
                <Contact />
                <Footer />
            </div>
        </>
    );
};

export default CorporateCyberTraining;
