import React from 'react';
import './appsec.css';
import { NavBar } from '../../components/NavBar';
import { Footer } from '../../components/Footer';
import redTeam from '../../assets/img/redteam.png';
import redTeam2 from '../../assets/img/redteaming2.png';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import { Contact } from '../../components/Contact';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO

const RedTeamingAssessment = () => {
    return (
        <>
            <Helmet>
                <title>Red Teaming Services - Zeroday Ops</title>
                <meta name="description" content="Our Red Teaming services provide a comprehensive assessment of your organization’s security posture by simulating real-world attacks to identify vulnerabilities and test your defenses." />
                <meta name="keywords" content="Red Teaming, Security Assessment, Vulnerability Testing, Cybersecurity Services" />
                {/* Organization Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "ZeroDay Ops",
          "url": "https://zerodayops.com/",
          "logo": "https://zerodayops.com/assets/img/logo192.png",
          "sameAs": [
            "https://www.linkedin.com/company/zeroday-ops/",
            "https://www.instagram.com/zerodayops/"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+91-9718047774",
            "contactType": "Customer Support",
            "areaServed": "US",
            "availableLanguage": "English"
          }
        }`}</script>

                {/* Breadcrumb Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://zerodayops.com/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Services",
              "item": "https://zerodayops.com/services"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Red Teaming",
              "item": "https://zerodayops.com/services/red-teaming"
            }
          ]
        }`}</script>

                {/* FAQ Schema */}
                <script type="application/ld+json">{`{
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "What is Red Teaming?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Red Teaming is a simulated cyberattack against your organization to assess the security of your systems."
              }
            },
            {
              "@type": "Question",
              "name": "Why is Red Teaming important?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "It helps organizations identify vulnerabilities before malicious actors can exploit them."
              }
            }
          ]
        }`}</script>
            </Helmet>
            <NavBar />
            <div className="appSec-container">
                <br />
                <div className="appSec-header">
                    <h1 className="appSec-title">
                        RED TEAMING SERVICES
                    </h1>
                    <p className="appSec-subtitle">
                        Our Red Teaming services provide a comprehensive assessment of your organization’s security posture by simulating real-world attacks to identify vulnerabilities and test your defenses.
                    </p>
                </div>
                <br />
                <div className="appSec-section">
                    <div className="appSec-overview">
                        <h2 className="appSec-overview-title">Red Teaming Services: Overview</h2>
                        <p className="appSec-overview-text">
                            Red Teaming involves emulating the tactics, techniques, and procedures of real-world adversaries to challenge your organization's defenses. This approach provides valuable insights into the effectiveness of your security measures and identifies areas for improvement to better protect your assets against sophisticated attacks.
                        </p>
                    </div>
                    <div className="appSec-image">
                        <img src={redTeam} style={{ width: '250px' }} alt="Red Teaming Services" />
                    </div>
                </div>
                <button className='appsecButton'>
                    <Link to='/contact' style={{ textDecoration: 'none', color: 'inherit' }}>
                        Get a Quote <ArrowRightCircle size={25} />
                    </Link>
                </button>
                <br />
                <br />
                <div className="appSec-overview">
                    <div className="appSec-methodology-row">
                        <div className="appSec-methodology-content">
                            <img src={redTeam2} alt="Methodology" style={{ width: "100%", height: "100%" }} />
                        </div>
                        <br />
                        <div className="appSec-methodology-text">
                            <h3 className="appSec-checklist-title">Methodology:</h3>
                            <br />
                            <div className="appSec-checklist-items">
                                <ul className="appSec-checklist-column">
                                    <li>Reconnaissance and Information Gathering</li>
                                    <li>Identifying Potential Attack Vectors</li>
                                    <li>Simulating Social Engineering Attacks</li>
                                </ul>
                                <ul className="appSec-checklist-column">
                                    <li>Testing Physical Security Measures</li>
                                    <li>Exploiting Identified Vulnerabilities</li>
                                    <li>Reporting and Providing Mitigation Recommendations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Contact />
            <Footer />
        </>
    );
};

export default RedTeamingAssessment;
