import React from "react";
import step1 from "../assets/process/step1.webp";
import step2 from "../assets/process/step2.webp";
import step3 from "../assets/process/step3.webp";
import step4 from "../assets/process/step4.png";

const ProcessComponent = () => {
  return (
    <section className="process-section">
      <h2 className="process-title">What’s our process?</h2>
      <p className="process-subtitle">Our streamlined approach to protect your IT infrastructure</p>
      
      <div className="process-steps">
        {/* Step 1 */}
        <div className="process-step">
          <img src={step1} alt="Step 1" className="process-image" />
          <h3>Step 1: Initial Consultation & Scope Definition</h3>
          <p>Understanding Product & Attack Surface</p>
        </div>
        
        {/* Step 2 */}
        <div className="process-step">
          <img src={step2} alt="Step 2" className="process-image" />
          <h3>Step 2: Vulnerability Assessment</h3>
          <p>Tools for vulnerability assessment on the basis of severity</p>
        </div>

        {/* Step 3 */}
        <div className="process-step">
          <img src={step3} alt="Step 3" className="process-image" />
          <h3>Step 3: Penetration Testing</h3>
          <p>Manually exploiting vulnerabilities and generating proof of concept</p>
        </div>

        {/* Step 4 */}
        <div className="process-step">
          <img src={step4} alt="Step 4" className="process-image" />
          <h3>Step 4: Detailed Reporting & Remediation Support</h3>
          <p>Share report detailing vulnerabilities and re-audit after patching</p>
        </div>
      </div>
    </section>
  );
};

export default ProcessComponent;
